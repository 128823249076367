<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getModelStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step :rules="[
                                () => getModelError.stepOne.validation,
                            ]" editable step="1">{{
        $t('models:modelInformation')
}}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator :text="
                                            $t('models:modelInformation')
                                        " />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field v-model="getModelModal.model" :error-messages="
                                            getModelError.stepOne.fields
                                                .model
                                        " :label="`${$t('models:model')}*`"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select :items="
                                            getRegistryDictionaries.modelSeries
                                                ? getRegistryDictionaries.modelSeries
                                                : []
                                        " v-model="getModelModal.modelSeries" multiple
                                            :label="$t('models:modelSeries')" item-value="field">
                                            <template v-slot:item="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list" v-else>
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list" v-else>
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="
                                                getModelModal.manufacturerIndex
                                            "
                                            :label="`${$t(
                                                'models:manufacturerIndex'
                                            )}`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-autocomplete :items="manufacturerList" :error-messages="
                                            getModelError.stepOne.fields
                                                .manufacturer
                                        " v-model="getModelModal.manufacturer" :label="`${$t(
    'models:manufacturer'
)}*`" item-value="field" item-text="field">


                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select :items="
                                            getRegistryDictionaries.deviceType
                                                ? getRegistryDictionaries.deviceType
                                                : []
                                        " :error-messages="
    getModelError.stepOne.fields
        .deviceType
" v-model="getModelModal.deviceType" :label="`${$t(
    'models:deviceType'
)}*`" item-value="field">
                                            <template v-slot:item="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list" v-else>
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list" v-else>
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select :items="getServicePricesItems" v-model="getModelModal.servicePrice"
                                            :error-messages="
                                                getModelError.stepOne.fields
                                                    .servicePrice
                                            " item-value="_id" :label="`${$t(
    'models:servicePrice'
)}*`">
                                            <template v-slot:selection="{
                                                item,
                                            }">
                                                <span>{{ item.name }}
                                                    {{ item.lastname }}</span>
                                            </template>
                                            <template v-slot:item="data">
                                                <span>{{ data.item.name }}
                                                    {{
                                                            data.item.lastname
                                                    }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" offset="4">
                                        <v-select :items="numberValue" v-model="
                                            getModelModal.competenciesLevel
                                        " :label="
    $t('models:competenciesLevel')
">
                                        </v-select>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-select :items="
                                            getRegistryDictionaries.options
                                                ? getRegistryDictionaries.options
                                                : []
                                        " multiple v-model="getModelModal.options" :label="$t('models:options')"
                                            item-value="field">
                                            <template v-slot:item="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list" v-else>
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span v-if="
                                                    data.item
                                                        .backgroundColor
                                                ">
                                                    <v-chip class="mx-2" :color="
                                                        data.item
                                                            .backgroundColor
                                                    " small label :text-color="
    data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'
">
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span class="truncate-list mr-1" v-else>
                                                    {{ data.item.field }},
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea v-model="getModelModal.description" rows="1" no-resize auto-grow
                                            :label="$t('models:description')">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            editable: true,
            numberValue: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        }
    },
    methods: {},

    computed: {
        ...mapGetters([
            'getModelModal',
            'getModelError',
            'getModelStep',
            'getServicePricesItems',
            'getRegistryDictionaries',
            'getDictionaries',
        ]),
        manufacturerList: {
            get() {
                if (this.getRegistryDictionaries.manufacturer) {

                    return this.getRegistryDictionaries.manufacturer.sort(
                        (a, b) => (a.field > b.field ? 1 : -1)
                    )
                }
                else return []
            },
        },
    },
}
</script>
