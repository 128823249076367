var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getModelStep.step),callback:function ($$v) {_vm.$set(_vm.getModelStep, "step", $$v)},expression:"getModelStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getModelError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('models:modelInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('models:modelInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getModelError.stepOne.fields
                                                .model,"label":`${_vm.$t('models:model')}*`},model:{value:(_vm.getModelModal.model),callback:function ($$v) {_vm.$set(_vm.getModelModal, "model", $$v)},expression:"getModelModal.model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.modelSeries
                                                ? _vm.getRegistryDictionaries.modelSeries
                                                : [],"multiple":"","label":_vm.$t('models:modelSeries'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getModelModal.modelSeries),callback:function ($$v) {_vm.$set(_vm.getModelModal, "modelSeries", $$v)},expression:"getModelModal.modelSeries"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                                'models:manufacturerIndex'
                                            )}`},model:{value:(
                                                _vm.getModelModal.manufacturerIndex
                                            ),callback:function ($$v) {_vm.$set(_vm.getModelModal, "manufacturerIndex", $$v)},expression:"\n                                                getModelModal.manufacturerIndex\n                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.manufacturerList,"error-messages":_vm.getModelError.stepOne.fields
                                                .manufacturer,"label":`${_vm.$t(
    'models:manufacturer'
)}*`,"item-value":"field","item-text":"field"},model:{value:(_vm.getModelModal.manufacturer),callback:function ($$v) {_vm.$set(_vm.getModelModal, "manufacturer", $$v)},expression:"getModelModal.manufacturer"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.deviceType
                                                ? _vm.getRegistryDictionaries.deviceType
                                                : [],"error-messages":_vm.getModelError.stepOne.fields
        .deviceType,"label":`${_vm.$t(
    'models:deviceType'
)}*`,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getModelModal.deviceType),callback:function ($$v) {_vm.$set(_vm.getModelModal, "deviceType", $$v)},expression:"getModelModal.deviceType"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getServicePricesItems,"error-messages":_vm.getModelError.stepOne.fields
                                                    .servicePrice,"item-value":"_id","label":`${_vm.$t(
    'models:servicePrice'
)}*`},scopedSlots:_vm._u([{key:"selection",fn:function({
                                                item,
                                            }){return [_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.lastname))])]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.lastname))])]}}]),model:{value:(_vm.getModelModal.servicePrice),callback:function ($$v) {_vm.$set(_vm.getModelModal, "servicePrice", $$v)},expression:"getModelModal.servicePrice"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('v-select',{attrs:{"items":_vm.numberValue,"label":_vm.$t('models:competenciesLevel')},model:{value:(
                                            _vm.getModelModal.competenciesLevel
                                        ),callback:function ($$v) {_vm.$set(_vm.getModelModal, "competenciesLevel", $$v)},expression:"\n                                            getModelModal.competenciesLevel\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.options
                                                ? _vm.getRegistryDictionaries.options
                                                : [],"multiple":"","label":_vm.$t('models:options'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
        ? data.item
            .textColor
        : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list mr-1"},[_vm._v(" "+_vm._s(data.item.field)+", ")])]}}]),model:{value:(_vm.getModelModal.options),callback:function ($$v) {_vm.$set(_vm.getModelModal, "options", $$v)},expression:"getModelModal.options"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"1","no-resize":"","auto-grow":"","label":_vm.$t('models:description')},model:{value:(_vm.getModelModal.description),callback:function ($$v) {_vm.$set(_vm.getModelModal, "description", $$v)},expression:"getModelModal.description"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }