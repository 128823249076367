<template>
    <v-container>
        <Header
            :registryStatus="getModelStatuses"
            :recordIndex="getModelDetails.index"
            :title="getModelDetails.name"
            :registryTitle="getModelsRegistry.name"
            :registryUrl="'models'"
            :actualStatus="getModelDetails.status"
            @status-changed="changeStatus"
            :avatarData="getModelDetails.createdBy"
        />
        <Main class="p-relative" :activeTab="this.$route.query.tabs">
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('models:editionModel')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editModel()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('models:editModel') }}</span>
                    </v-tooltip>
                </div>
                <span></span>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Models/Details/Information'
import Buttons from './../../../components//Registries/Models/Modal/Buttons'
import AEContent from './../../../components//Registries/Models/Modal/Content'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: { Information, Buttons, AEContent },
    data() {
        return {
            open: false,
            activeTab: 'information',
        }
    },
    computed: {
        ...mapGetters([
            'getModelStatuses',
            'getModelDetails',
            'getModelsRegistry',
            'getModelStep',
        ]),
    },
    methods: {
        ...mapMutations(['clearModelErrors', 'setModelModal']),
        ...mapActions([
            'fetchModel',
            'updateModelStatus',
            'fetchServicePricesItems',
        ]),
        async editModel() {
            await void this.fetchServicePricesItems({
                select: 'name ',
                where: { isDeleted: false },
            })
            this.open = true
            this.clearModelErrors()
            this.setModelModal()
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateModelStatus({
                status,
                id: this.getModelDetails._id,
            })
        },
        closeModal() {
            this.clearModelErrors()
            this.open = false
            this.getModelStep.step = 1
        },
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchModel', { id: to.params.id, next })
    },
}
</script>
