var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.getModelDetails.model)+" ")]),_c('span',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.getModelDetails.modelSeries))]),_c('span',{staticClass:"mt-5"},[_c('b',[_vm._v(_vm._s(`${_vm.$t('models:deviceType')}`)+":")]),_vm._v(" "+_vm._s(_vm.getModelDetails.deviceType))]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('models:manufacturer')}`)+":")]),_vm._v(" "+_vm._s(_vm.getModelDetails.manufacturer))]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('models:servicePrice')}`)+":")]),_vm._v(" "+_vm._s(`${_vm.$get( _vm.getModelDetails, 'servicePrice.name', '' )}`)+" ")])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('models:modelInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.model,"label":_vm.$t('models:model')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.modelSeries
                                ? _vm.$t(
                                      `models:${_vm.getModelDetails.modelSeries}`
                                  )
                                : '',"label":_vm.$t('models:modelSeries')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.manufacturerIndex,"label":_vm.$t('models:manufacturerIndex')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.manufacturer,"label":_vm.$t('models:manufacturer')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.deviceType,"label":_vm.$t('models:deviceType')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"readonly":"","value":`${_vm.$get(
                                    _vm.getModelDetails,
                                    'servicePrice.name',
                                    ''
                                )}`,"label":_vm.$t('models:servicePrice')}},on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.prices))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset-sm":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getModelDetails.competenciesLevel,"label":_vm.$t('models:competenciesLevel')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"option",attrs:{"label":_vm.$t('models:options'),"multiple":"","readonly":"","items":_vm.getModelDetails.options},model:{value:(_vm.getModelDetails.options),callback:function ($$v) {_vm.$set(_vm.getModelDetails, "options", $$v)},expression:"getModelDetails.options"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","rows":"1","auto-grow":"","no-resize":"","value":_vm.$t(`models:${_vm.getModelDetails.description}`),"label":_vm.$t('models:description')}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }