<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="
                                font-weight-bold
                                d-flex
                                flex-column
                                headline
                                pa-0
                                ma-0
                            "
                        >
                            {{ getModelDetails.model }}
                        </p>
                        <span class="mb-5">{{
                            getModelDetails.modelSeries
                        }}</span>
                        <span class="mt-5"
                            ><b>{{ `${$t('models:deviceType')}` }}:</b>
                            {{ getModelDetails.deviceType }}</span
                        >
                        <span
                            ><b>{{ `${$t('models:manufacturer')}` }}:</b>
                            {{ getModelDetails.manufacturer }}</span
                        >
                        <span
                            ><b>{{ `${$t('models:servicePrice')}` }}:</b>
                            {{
                                `${$get(
                                    getModelDetails,
                                    'servicePrice.name',
                                    ''
                                )}`
                            }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('models:modelInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="getModelDetails.model"
                            :label="$t('models:model')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="
                                getModelDetails.modelSeries
                                    ? $t(
                                          `models:${getModelDetails.modelSeries}`
                                      )
                                    : ''
                            "
                            :label="$t('models:modelSeries')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="getModelDetails.manufacturerIndex"
                            :label="$t('models:manufacturerIndex')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="getModelDetails.manufacturer"
                            :label="$t('models:manufacturer')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="getModelDetails.deviceType"
                            :label="$t('models:deviceType')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4"
                        ><v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-on="on"
                                    readonly
                                    :value="`${$get(
                                        getModelDetails,
                                        'servicePrice.name',
                                        ''
                                    )}`"
                                    :label="$t('models:servicePrice')"
                                ></v-text-field>
                            </template>
                            <span>{{ prices }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" offset-sm="4">
                        <v-text-field
                            readonly
                            :value="getModelDetails.competenciesLevel"
                            :label="$t('models:competenciesLevel')"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                        <v-select
                            :label="$t('models:options')"
                            class="option"
                            multiple
                            readonly
                            :items="getModelDetails.options"
                            v-model="getModelDetails.options"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            rows="1"
                            auto-grow
                            no-resize
                            :value="$t(`models:${getModelDetails.description}`)"
                            :label="$t('models:description')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['models'] },
    computed: {
        ...mapGetters(['getModelDetails']),
        prices: {
            get() {
                let prices =
                    this.$get(this.getModelDetails, 'servicePrice') || {}

                let str = ''
                str += `${this.$t('service:survey')}: ${Number(
                    this.$get(prices, 'survey.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:repair')}: ${Number(
                    this.$get(prices, 'repair.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:installation')}: ${Number(
                    this.$get(prices, 'installation.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:diagnostics')}: ${Number(
                    this.$get(prices, 'diagnostics.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:itService')}: ${Number(
                    this.$get(prices, 'itService.price', 0)
                ).toFixed(2)} PLN / `
                str += `${this.$t('service:remote')}: ${Number(
                    this.$get(prices, 'remote.price', 0)
                ).toFixed(2)} PLN `
                return str
            },
        },
    },
}
</script>
<style lang="sass" scoped>
.option > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection
    color: rgba(0, 0, 0, 1) !important
</style>
